import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/lssd_blog_header.png");
const section_1 = require("../../../assets/img/blogs/lssd_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/lssd_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/lssd_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/lssd_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/lssd_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/lssd_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/lssd_blog_img_7.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Security Best Practices to Use LLMs for Service Desk Automation "
        description="Leveraging security best practices to use LLMs is the best approach to maximizing your service desk. Embrace Workativ to gain the best benefits of Gen AI."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt lssd_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img ${
                  isMobile ? "text-align-center" : "float-left"
                }`}
              >
                {isMobile ? (
                  <h1
                    className={`${
                      isMobile
                        ? "font-page-header-home-blog-gpt"
                        : "font-page-header-home-blog-gpt"
                    }`}
                  >
                    Security Best Practices to Use LLMs for Service Desk
                    Automation
                  </h1>
                ) : (
                  <h1
                    className={`${
                      isMobile
                        ? "font-page-header-home-blog"
                        : "font-page-header-home-blog"
                    }`}
                  >
                    Security Best Practices to
                    <br /> Use LLMs for Service Desk
                    <br />
                    Automation
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Benefits of LLMs for service desk
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Threats of LLMs to service desk operations
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Best practices to improve LLM efficiency for service desk
                  automation
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Workativ X Hybrid NLU: A powerful approach to improving
                  service desk automation
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                In our previous article, we’ve delved deep into discovering the
                potential of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itops">
                  Generative AI for ITSM or AIOps,
                </a>{" "}
                where users can unravel different ways to leverage the potential
                of LLMs to automate critical tasks and gain immense benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Likewise, the potential to transform user experience with
                service request fulfillment using Generative AI or its
                underlying properties large language model 一 more usually what
                we call GPT 3.5 or GPT 4, can also be transformative or
                revolutionary.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of many advantages that Generative AI unleashes for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  service desk automation
                </a>{" "}
                is that SMEs need to make fewer efforts to create, design,
                approve, and publish knowledge base resources for a wider
                variety of enterprise use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  large language models
                </a>{" "}
                providing a significant boost in knowledge search, enterprise
                leaders can facilitate effortless service request management and
                provide an elevated user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, as the old saying goes, ‘All things have their own limits,’
                large language models also have certain limitations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not every language model is the same, including the
                domain-specific use cases for which the LLMs are applied. In
                such a scenario, the language model behavior can be different.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Without real-time application monitoring, data protection, and
                access control, there could be serious vulnerabilities to
                service desk operations, impacting user experience while
                threatening the business's reputation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What best practice should you adopt to use a large language
                model safely to maximize the potential of LLMs to facilitate
                operational and productivity efficiency and prevent threats to
                enterprise security?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s walk you through this article, where we will discuss the
                best security practice for using LLMs for your service desk
                operations. We will also discuss the benefits and
                vulnerabilities of{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk/">
                  large language models to service desk
                </a>
                automation.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Benefits of LLMs for service desk
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Large language models help facilitate a wide range of tasks
                through seamless automation, which saves service desk team time
                and give more energy to solve critical service request.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 mb-1">
                <li className="color-black">
                  Quick response to user requests{" "}
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Service desk automation makes it easy to resolve issues at scale
                through self-service autonomously. But it isn’t unfair to say
                that the service desk also receives requests which need expert
                attention, such as requests for miscalculated compensation or
                payroll error. In such a circumstance, Generative AI helps the
                service desk prioritize the request, route optimization, and
                resolve fast.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 mb-1">
                <li className="color-black">
                  Self-help through enriched response{" "}
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Generative AI better resolves employee queries by refining
                service request responses across its models or databases. It is
                more capable of offering more personalized and humanized
                suggestions over time. Say you quickly need to know about
                monthly tax payments, a Generative AI gives a straightforward
                answer with total calculations rather than asking you to search
                through regular tax calculation documents or URLs and make
                personal assumptions.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 mb-1">
                <li className="color-black">
                  The quick development of knowledge bases{" "}
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Any unique service requests and their resolutions need to be
                documented. Manual classification of unique service requests can
                be tedious, error-prone, and even not timely drafted,
                documented, and published. Generative AI eliminates the pain and
                accelerates summarization, drafting, and publishing tasks
                through automated workflows.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Generative AI-based SaaS applications such as service desk&nbsp;
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI chatbots
                </a>{" "}
                bring immense opportunities for enterprises to enhance the
                service desk experience by improving productivity through
                automated workflows and quick diagnosis of emergency service
                requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                However, while Generative AI can make use of Large Language
                Models to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  transform service desks,
                </a>{" "}
                they can also be vulnerable to user experience. Not just do LLMs
                expose sensitive data to those who want to satisfy their
                illegitimate expectations and trigger multiple vulnerabilities
                to raise compliance issues and reputational damage.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Threats of LLMs to service desk operations
              </h2>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #1 Prompt injection attacks
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" prompt injection attacks in llms"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a service management setting, prompt injection attacks could
                be as threatful as other cybersecurity vulnerabilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using prompt injection into the large language models, attackers
                can take control of the underlying infrastructure of the model
                and easily manipulate them to ignore its safety guardrails or
                behave indifferently to quash its embedded instructions and
                instead perform unintended tasks.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Taking advantage of weaknesses in the model, prompt
                    injections use certain types of language patterns or tokens
                    to trick the model into doing illegitimate tasks.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    When attacked by prompt injections, a compromised LLM can
                    influence a user to perform tasks that violate workplace
                    sanctity and compromise compliance.
                  </span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, when an LLM-integrated chatbot goes offline, a
                compromised LLM application can take over and perform a task
                without raising suspicion.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A marketing bot is instructed to email the company’s existing
                customer contacts about a promotional offer for a new product
                launch. A compromised LLM shares an email template for the bot
                to follow exactly how it is written. That bot may do what it is
                asked to, although it isn’t intended on the company front.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Below is an example of a compromised LLM-generated instruction
                to the marketing department bot:
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="a compromised LLM instruction to do a malicious activity "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                Another instance of prompt injection is malicious bots can
                intrigue users into divulging financial information and harm
                them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A user complains about a misadjustment in compensation. The
                compromised LLM can trick the user into providing his credit
                card information as it assures him of real-time or instant
                payment adjustment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                When he discloses his information, he can lose all his money or
                fall prey to unintentional workplace fraud.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="an example of a trick to reveal user credit card information through prompt injections
"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                The example conversation shows how injected prompts can easily
                steal a user’s credit card information and put service desk
                operations in trouble.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can well imagine the confusion and frustrations for the
                whole department, not to mention the reputational damage.
              </p>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #2 Disclosure of secret data
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                During LLM’s training process, chances are the training
                environment may expose LLM to sensitive data, especially PII
                personally identifiable information, proprietary company data,
                or other sensitive data unintentionally.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This can lead to intentional or unintentional scenarios of data
                leakage of sensitive information.
              </p>
              <h4 className="font-section-normal-text-medium">Scenario 1: </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                A legitimate user can inadvertently use prompts that would
                reveal sensitive data he wasn’t aware of.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is similar to the Samsung incident, where they
                inadvertently disclosed the company’s data publicly.
              </p>
              <h4 className="font-section-normal-text-medium">Scenario 2: </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                A cybersecurity attacker would create careful prompts that work
                in the LLM interface and reveal secret data by manipulating LLM
                to retrieve data from memorization of training.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s say an attacker can craft prompts to ask the LLM interface
                to share company contacts for a certain solution similar to
                their competition.
              </p>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #3 Hallucinated responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs can make up facts and generate a hallucinated response,
                content, or other resources. This probably occurs as a large
                language model trains on massive crawled datasets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Another reason is LLMs are not connected to search engines that
                can produce real-time factual and grounded information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On top of it, LLMs are not trained to verify the fact of the
                data they are producing, and as a result, they can generate
                responses that can be hallucinated. Interestingly, the
                hallucinated responses LLMs generate are confident and
                compelling in nature to influence user acceptance.
              </p>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #4 Bias and discrimination
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM can lose its reasoning and intelligence to perpetuate bias
                and discrimination. This happens due to faulty training data,
                which may contain discriminatory data. As a result, human
                reasoning can also get affected and lead to risky environments
                in the workplace and everywhere.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, Generative AI can create content that contains
                offensive language to be sent to clients. Or, most probably,
                during a one-on-one chat with an{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  enterprise bot,
                </a>{" "}
                it can issue misleading guidance to an employee.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Best practices to improve LLM efficiency for service desk
                automation
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/generative-ai-enterprise">
                  {" "}
                  Generative AI is revolutionary for enterprise processes.
                </a>
                Appropriate safety guardrails and good governance are mandatory
                to maximize its use cases and benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What can you do to avoid Generative AI limitations and use its
                potential to{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  improve service desk automation?
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                #1 Restricting prompt injection using careful LLM training
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Vulnerabilities like prompt injection attacks can be restricted
                using model fine-tuning or preprocessing data before they are
                fed into the model. At a time when data sanitization removes the
                likelihood of LLMs searching across the database, it also does
                not disclose information that is otherwise harmful to the
                organization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                One example of fine-tuning the LLM model to reduce prompt
                injection is using specific instructions and training the model
                not to reveal any secret information.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Protecting user privacy with the right instruction during LLL training
                "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Another effective way to restrict prompt injection attacks is by
                leveraging the Query Classification model to be embedded inside
                the Generative AI solutions to prevent attempts of prompt
                manipulation by applying filters.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Filtering prompt injections using query-classifier - one of the ways to limit security threats to LLMs
                "
              ></img>
              <h3 className="font-section-sub-header-small-home">
                #2 Personal information masking to prevent personal data leakage
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In an enterprise context, there are many scenarios where
                employees can seek financial information such as compensation or
                tax-related questions. Chances are likely that LLMs can
                inadvertently or intentionally ask for Personal Identification
                Numbers (PII) or Payment Card Industry data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To reduce the likelihood of these incidents, look for if
                training data contains any PCI Or PII information. Remove these
                pieces of information and mask them with an additional security
                layer so that hijackers cannot unmask the information if there
                is any leakage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an employee has serious health complications, it
                is desirable that he wants to keep this information protected.
                When embedded in an LLM-powered chat interface, a multi-layer
                approach will reduce the exposure of sensitive information and
                keep it extremely private.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #3 Combating hallucinations using conversational AI and
                KnowledgeAI
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                LLMs tend to behave differently due to inadequate
                enterprise-grade or domain-specific data. With a low level of
                understanding of input data, users can retrieve incorrect
                information. Nonsensical responses can have negative business
                outcomes and trigger reputational damage.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt=" improving response quality using integration with KnowledgeAI and Conversational AI 

                "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the hallucinations' probability will decrease by
                integrating a large language model with the power of
                conversational AI and KnowledgeAI.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whenever an enterprise-grade query is raised, an LLM will pass
                it to the KnowledgeAI and extract the contextual element from
                conversational AI to produce a factual response.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead, it returns the following response,
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Sorry, I have no answer to your question.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this situation, a fallback action can take place.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #4 Mitigating bias using adversarial debiasing training
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To improve the resilience of large language models to reduce the
                tendency of biases,{" "}
                <a href="https://www.microsoft.com/en-us/research/publication/adversarial-training-for-large-neural-language-models/">
                  adversarial training works best.
                </a>{" "}
                By ingesting the model with sample data of adversarial attacks,
                developers can build adversarial perturbation to impact bias in
                the resulting embeddings and safeguard against adversarial
                attacks. As a result, LLMs are less susceptible to adversarial
                losses and restrict biases in the workplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an AI system is trained on a specific period of
                data of an organization that reflects a certain pattern in
                applications forwarded by male candidates in large volume rather
                than female candidates, the AI model will absorb this pattern
                and learn to favor male candidates only. As a result, in a
                real-world scenario, the model may ignore female candidates and
                prefer male candidacy, which is likely to foster gender-neutral
                bias. With the help of adversarial debiasing techniques or word
                embedding debiasing, gender-specific bias can be restricted in
                the workplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not only this, culture, race, or ethnicity-related bias can be
                mitigated using the same adversarial debiasing techniques.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #5 Human-in-the-loop
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                No one can replace human intelligence, and it’s about providing
                real-time validation to any response, humans are indispensable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s not underestimate the power of human intelligence's
                real-time supervision and monitoring capability that helps
                create non-biased responses by reviewing, editing, and approving
                before they are passed to the live environment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, the best approach is to ensure that enterprise
                conversations are intricately built in compliance with standard
                safety and quality guidelines.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This approach also helps comply with regulatory compliance such
                as GDPR, HIPPA, CCPA, and many others.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #6 Regularizing model testing and audits
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                What can be the most effective way to mitigate LLMs risks and
                improve user expeirene rather than leveraging advanced
                reporting? Advanced analytics and reporting are handy with
                Generative AI solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using the reporting dashboards, you can easily reveal service
                request data and find metrics to improve LLM's capabilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Based on your analytics report, you can easily find loopholes in
                the capability to solve users’ problems and fine-tune the model
                to act more in a humanized way.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ X Hybrid NLU: A powerful approach to improving service
                desk automation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/"> Workativ</a> brings to you the
                power of hybrid NLU built within its
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  conversational AI platform
                </a>{" "}
                that provides app workflow automation capability. This is an
                advanced Generative AI solution with the capability of
                conversational AI that helps you achieve enterprise use cases
                for a diverse range of service requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the ability to build your large language model with
                industry-specific data around the service requests or IT issues,
                domain-specific data, or Workativ’s data (data containing common
                enterprise-related IT issues), you can build a robust database
                to improve user response and help your employees get a response
                to their workplace issues in real-time and enhance their
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Workativ uses hybrid NLU to augment response validity by
                ensuring every query finds an appropriate match and offers
                real-time responses to improve user satisfaction.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Hybrid NLU to improve intent detection and response enrichment 

                "
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                Available 24x7, anywhere, anytime, Workativ conversational AI
                can allow you to build your{" "}
                <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                  IT support chatbot,
                </a>{" "}
                making it easy to create app workflow automation for any ITSM
                platform to solve workplace issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using virtual assistants by Workativ, users have the flexibility
                to automate 80% of repetitive IT support issues, achieve 90%
                first contact resolution, and reduce MTTR by 5X.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                To learn more about Workativ’s KnowledgeAI feature that allows
                you to leverage Generative AI and LLM,{" "}
                <a href="/conversational-ai-platform/demo">
                  get in touch with our sales experts.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                We must accept that no innovation can have only a positive
                spectrum. It has certain downsides.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To unleash the best of something requires patience and wearing
                an inclusive attitude. Excluding only pertains to depriving or
                discarding the best thing.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Large language models are not new but need time to evolve and
                scale. To gain significant business benefits out of LLMs,
                patience is key.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Above anything else, continuous model training and audits are
                imperative to improve their capabilities and bring more useful
                use cases for enterprises and mankind.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over time, the large language models are supposed to become more
                powerful and facilitate a wide user experience everywhere with
                expanding use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Want to learn how it is easy and fast to transform your service
                desk with LLM's best security practices?{" "}
                <a href="/conversational-ai-platform/demo">
                  Connect with Workativ
                </a>
                &nbsp;today.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Benefits of LLMs for service desk
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Threats of LLMs to service desk operations
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Best practices to improve LLM efficiency for service desk
                    automation
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Workativ X Hybrid NLU: A powerful approach to improving
                    service desk automation
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                In our previous article, we’ve delved deep into discovering the
                potential of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itops">
                  Generative AI for ITSM or AIOps,
                </a>{" "}
                where users can unravel different ways to leverage the potential
                of LLMs to automate critical tasks and gain immense benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Likewise, the potential to transform user experience with
                service request fulfillment using Generative AI or its
                underlying properties large language model 一 more usually what
                we call GPT 3.5 or GPT 4, can also be transformative or
                revolutionary.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of many advantages that Generative AI unleashes for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  service desk automation
                </a>{" "}
                is that SMEs need to make fewer efforts to create, design,
                approve, and publish knowledge base resources for a wider
                variety of enterprise use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  large language models
                </a>{" "}
                providing a significant boost in knowledge search, enterprise
                leaders can facilitate effortless service request management and
                provide an elevated user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, as the old saying goes, ‘All things have their own limits,’
                large language models also have certain limitations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not every language model is the same, including the
                domain-specific use cases for which the LLMs are applied. In
                such a scenario, the language model behavior can be different.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Without real-time application monitoring, data protection, and
                access control, there could be serious vulnerabilities to
                service desk operations, impacting user experience while
                threatening the business's reputation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What best practice should you adopt to use a large language
                model safely to maximize the potential of LLMs to facilitate
                operational and productivity efficiency and prevent threats to
                enterprise security?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s walk you through this article, where we will discuss the
                best security practice for using LLMs for your service desk
                operations. We will also discuss the benefits and
                vulnerabilities of{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk/">
                  large language models to service desk
                </a>
                automation.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Benefits of LLMs for service desk
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Large language models help facilitate a wide range of tasks
                through seamless automation, which saves service desk team time
                and give more energy to solve critical service request.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 mb-1">
                <li className="color-black">
                  Quick response to user requests{" "}
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Service desk automation makes it easy to resolve issues at scale
                through self-service autonomously. But it isn’t unfair to say
                that the service desk also receives requests which need expert
                attention, such as requests for miscalculated compensation or
                payroll error. In such a circumstance, Generative AI helps the
                service desk prioritize the request, route optimization, and
                resolve fast.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 mb-1">
                <li className="color-black">
                  Self-help through enriched response{" "}
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Generative AI better resolves employee queries by refining
                service request responses across its models or databases. It is
                more capable of offering more personalized and humanized
                suggestions over time. Say you quickly need to know about
                monthly tax payments, a Generative AI gives a straightforward
                answer with total calculations rather than asking you to search
                through regular tax calculation documents or URLs and make
                personal assumptions.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 mb-1">
                <li className="color-black">
                  The quick development of knowledge bases{" "}
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Any unique service requests and their resolutions need to be
                documented. Manual classification of unique service requests can
                be tedious, error-prone, and even not timely drafted,
                documented, and published. Generative AI eliminates the pain and
                accelerates summarization, drafting, and publishing tasks
                through automated workflows.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Generative AI-based SaaS applications such as service desk&nbsp;
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI chatbots
                </a>{" "}
                bring immense opportunities for enterprises to enhance the
                service desk experience by improving productivity through
                automated workflows and quick diagnosis of emergency service
                requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                However, while Generative AI can make use of Large Language
                Models to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  transform service desks,
                </a>{" "}
                , they can also be vulnerable to user experience. Not just do
                LLMs expose sensitive data to those who want to satisfy their
                illegitimate expectations and trigger multiple vulnerabilities
                to raise compliance issues and reputational damage.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Threats of LLMs to service desk operations
              </h2>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #1 Prompt injection attacks
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" prompt injection attacks in llms"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a service management setting, prompt injection attacks could
                be as threatful as other cybersecurity vulnerabilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using prompt injection into the large language models, attackers
                can take control of the underlying infrastructure of the model
                and easily manipulate them to ignore its safety guardrails or
                behave indifferently to quash its embedded instructions and
                instead perform unintended tasks.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Taking advantage of weaknesses in the model, prompt
                    injections use certain types of language patterns or tokens
                    to trick the model into doing illegitimate tasks.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    When attacked by prompt injections, a compromised LLM can
                    influence a user to perform tasks that violate workplace
                    sanctity and compromise compliance.
                  </span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, when an LLM-integrated chatbot goes offline, a
                compromised LLM application can take over and perform a task
                without raising suspicion.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A marketing bot is instructed to email the company’s existing
                customer contacts about a promotional offer for a new product
                launch. A compromised LLM shares an email template for the bot
                to follow exactly how it is written. That bot may do what it is
                asked to, although it isn’t intended on the company front.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Below is an example of a compromised LLM-generated instruction
                to the marketing department bot:
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="a compromised LLM instruction to do a malicious activity "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                Another instance of prompt injection is malicious bots can
                intrigue users into divulging financial information and harm
                them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A user complains about a misadjustment in compensation. The
                compromised LLM can trick the user into providing his credit
                card information as it assures him of real-time or instant
                payment adjustment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                When he discloses his information, he can lose all his money or
                fall prey to unintentional workplace fraud.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="an example of a trick to reveal user credit card information through prompt injections
"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                The example conversation shows how injected prompts can easily
                steal a user’s credit card information and put service desk
                operations in trouble.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can well imagine the confusion and frustrations for the
                whole department, not to mention the reputational damage.
              </p>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #2 Disclosure of secret data
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                During LLM’s training process, chances are the training
                environment may expose LLM to sensitive data, especially PII
                personally identifiable information, proprietary company data,
                or other sensitive data unintentionally.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This can lead to intentional or unintentional scenarios of data
                leakage of sensitive information.
              </p>
              <h4 className="font-section-normal-text-medium">Scenario 1: </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                A legitimate user can inadvertently use prompts that would
                reveal sensitive data he wasn’t aware of.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is similar to the Samsung incident, where they
                inadvertently disclosed the company’s data publicly.
              </p>
              <h4 className="font-section-normal-text-medium">Scenario 2: </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                A cybersecurity attacker would create careful prompts that work
                in the LLM interface and reveal secret data by manipulating LLM
                to retrieve data from memorization of training.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s say an attacker can craft prompts to ask the LLM interface
                to share company contacts for a certain solution similar to
                their competition.
              </p>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #3 Hallucinated responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs can make up facts and generate a hallucinated response,
                content, or other resources. This probably occurs as a large
                language model trains on massive crawled datasets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Another reason is LLMs are not connected to search engines that
                can produce real-time factual and grounded information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On top of it, LLMs are not trained to verify the fact of the
                data they are producing, and as a result, they can generate
                responses that can be hallucinated. Interestingly, the
                hallucinated responses LLMs generate are confident and
                compelling in nature to influence user acceptance.
              </p>
              <h3 className="font-section-sub-header-small-home">
                LLM Threat #4 Bias and discrimination
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM can lose its reasoning and intelligence to perpetuate bias
                and discrimination. This happens due to faulty training data,
                which may contain discriminatory data. As a result, human
                reasoning can also get affected and lead to risky environments
                in the workplace and everywhere.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, Generative AI can create content that contains
                offensive language to be sent to clients. Or, most probably,
                during a one-on-one chat with an{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  enterprise bot,
                </a>{" "}
                it can issue misleading guidance to an employee.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Best practices to improve LLM efficiency for service desk
                automation
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/generative-ai-enterprise">
                  {" "}
                  Generative AI is revolutionary for enterprise processes.
                </a>
                Appropriate safety guardrails and good governance are mandatory
                to maximize its use cases and benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What can you do to avoid Generative AI limitations and use its
                potential to{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  improve service desk automation?
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                #1 Restricting prompt injection using careful LLM training
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Vulnerabilities like prompt injection attacks can be restricted
                using model fine-tuning or preprocessing data before they are
                fed into the model. At a time when data sanitization removes the
                likelihood of LLMs searching across the database, it also does
                not disclose information that is otherwise harmful to the
                organization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                One example of fine-tuning the LLM model to reduce prompt
                injection is using specific instructions and training the model
                not to reveal any secret information.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Protecting user privacy with the right instruction during LLL training
                "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Another effective way to restrict prompt injection attacks is by
                leveraging the Query Classification model to be embedded inside
                the Generative AI solutions to prevent attempts of prompt
                manipulation by applying filters.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Filtering prompt injections using query-classifier - one of the ways to limit security threats to LLMs
                "
              ></img>
              <h3 className="font-section-sub-header-small-home">
                #2 Personal information masking to prevent personal data leakage
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In an enterprise context, there are many scenarios where
                employees can seek financial information such as compensation or
                tax-related questions. Chances are likely that LLMs can
                inadvertently or intentionally ask for Personal Identification
                Numbers (PII) or Payment Card Industry data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To reduce the likelihood of these incidents, look for if
                training data contains any PCI Or PII information. Remove these
                pieces of information and mask them with an additional security
                layer so that hijackers cannot unmask the information if there
                is any leakage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an employee has serious health complications, it
                is desirable that he wants to keep this information protected.
                When embedded in an LLM-powered chat interface, a multi-layer
                approach will reduce the exposure of sensitive information and
                keep it extremely private.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #3 Combating hallucinations using conversational AI and
                KnowledgeAI
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                LLMs tend to behave differently due to inadequate
                enterprise-grade or domain-specific data. With a low level of
                understanding of input data, users can retrieve incorrect
                information. Nonsensical responses can have negative business
                outcomes and trigger reputational damage.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt=" improving response quality using integration with KnowledgeAI and Conversational AI 

                "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the hallucinations' probability will decrease by
                integrating a large language model with the power of
                conversational AI and KnowledgeAI.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whenever an enterprise-grade query is raised, an LLM will pass
                it to the KnowledgeAI and extract the contextual element from
                conversational AI to produce a factual response.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead, it returns the following response,
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Sorry, I have no answer to your question.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this situation, a fallback action can take place.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #4 Mitigating bias using adversarial debiasing training
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To improve the resilience of large language models to reduce the
                tendency of biases,{" "}
                <a href="https://www.microsoft.com/en-us/research/publication/adversarial-training-for-large-neural-language-models/">
                  adversarial training works best.
                </a>{" "}
                By ingesting the model with sample data of adversarial attacks,
                developers can build adversarial perturbation to impact bias in
                the resulting embeddings and safeguard against adversarial
                attacks. As a result, LLMs are less susceptible to adversarial
                losses and restrict biases in the workplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an AI system is trained on a specific period of
                data of an organization that reflects a certain pattern in
                applications forwarded by male candidates in large volume rather
                than female candidates, the AI model will absorb this pattern
                and learn to favor male candidates only. As a result, in a
                real-world scenario, the model may ignore female candidates and
                prefer male candidacy, which is likely to foster gender-neutral
                bias. With the help of adversarial debiasing techniques or word
                embedding debiasing, gender-specific bias can be restricted in
                the workplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not only this, culture, race, or ethnicity-related bias can be
                mitigated using the same adversarial debiasing techniques.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #5 Human-in-the-loop
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                No one can replace human intelligence, and it’s about providing
                real-time validation to any response, humans are indispensable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s not underestimate the power of human intelligence's
                real-time supervision and monitoring capability that helps
                create non-biased responses by reviewing, editing, and approving
                before they are passed to the live environment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, the best approach is to ensure that enterprise
                conversations are intricately built in compliance with standard
                safety and quality guidelines.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This approach also helps comply with regulatory compliance such
                as GDPR, HIPPA, CCPA, and many others.
              </p>
              <h3 className="font-section-sub-header-small-home">
                #6 Regularizing model testing and audits
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                What can be the most effective way to mitigate LLMs risks and
                improve user expeirene rather than leveraging advanced
                reporting? Advanced analytics and reporting are handy with
                Generative AI solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using the reporting dashboards, you can easily reveal service
                request data and find metrics to improve LLM's capabilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Based on your analytics report, you can easily find loopholes in
                the capability to solve users’ problems and fine-tune the model
                to act more in a humanized way.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ X Hybrid NLU: A powerful approach to improving service
                desk automation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/"> Workativ</a> brings to you the
                power of hybrid NLU built within its
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  conversational AI platform
                </a>{" "}
                that provides app workflow automation capability. This is an
                advanced Generative AI solution with the capability of
                conversational AI that helps you achieve enterprise use cases
                for a diverse range of service requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the ability to build your large language model with
                industry-specific data around the service requests or IT issues,
                domain-specific data, or Workativ’s data (data containing common
                enterprise-related IT issues), you can build a robust database
                to improve user response and help your employees get a response
                to their workplace issues in real-time and enhance their
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Workativ uses hybrid NLU to augment response validity by
                ensuring every query finds an appropriate match and offers
                real-time responses to improve user satisfaction.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Hybrid NLU to improve intent detection and response enrichment 

                "
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                Available 24x7, anywhere, anytime, Workativ conversational AI
                can allow you to build your{" "}
                <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                  IT support chatbot,
                </a>{" "}
                making it easy to create app workflow automation for any ITSM
                platform to solve workplace issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using virtual assistants by Workativ, users have the flexibility
                to automate 80% of repetitive IT support issues, achieve 90%
                first contact resolution, and reduce MTTR by 5X.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                To learn more about Workativ’s KnowledgeAI feature that allows
                you to leverage Generative AI and LLM,{" "}
                <a href="/conversational-ai-platform/demo">
                  get in touch with our sales experts.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                We must accept that no innovation can have only a positive
                spectrum. It has certain downsides.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To unleash the best of something requires patience and wearing
                an inclusive attitude. Excluding only pertains to depriving or
                discarding the best thing.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Large language models are not new but need time to evolve and
                scale. To gain significant business benefits out of LLMs,
                patience is key.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Above anything else, continuous model training and audits are
                imperative to improve their capabilities and bring more useful
                use cases for enterprises and mankind.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over time, the large language models are supposed to become more
                powerful and facilitate a wide user experience everywhere with
                expanding use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Want to learn how it is easy and fast to transform your service
                desk with LLM's best security practices?{" "}
                <a href="/conversational-ai-platform/demo">
                  Connect with Workativ
                </a>
                &nbsp;today.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk"
              className="font-section-normal-text-testimonials"
            >
              A Complete Guide Helpdesk vs Service desk
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/chatgpt-vs-conversational-ai"
              className="font-section-normal-text-testimonials"
            >
              Why Is ChatGPT Not The Answer To Your Enterprise Conversational AI
              Needs?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/the-service-desk-trends-and-priorities-for-2023"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              The Service Desk– Trends and priorities for 2022
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
